import React from 'react';
import { apiClient } from '../context-helpers/ApiClient';

export const OrderService = () => {

  const getOrders = async (inputData: any) => {
    let apiOptions: any = {
      url: `orders/query`,
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getOrderById = async (inputData: any) => {
    let apiOptions: any = {
      url: `orders/${inputData.orderId}/details`,
      method: 'get'
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const acceptOrder = async (inputData: any) => {
    let apiOptions: any = {
      url: `order/${inputData.orderId}/accept`,
      method: 'put'
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const cancelOrder = async (inputData: any) => {
    let apiOptions: any = {
      url: `order/${inputData.orderId}/cancel`,
      method: 'put',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }
  const shipOrder = async (inputData: any) => {
    let apiOptions: any = {
      url: `order/${inputData.orderId}/ship`,
      method: 'put'
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const deliverOrder = async (inputData: any) => {
    let apiOptions: any = {
      url: `order/${inputData.orderId}/deliver`,
      method: 'put'
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  return { getOrders, getOrderById, acceptOrder, cancelOrder, shipOrder, deliverOrder };
}