import { session } from "@fx/components";
import _ from "lodash";
import { useEffect } from "react";
import { SecurityService } from "../../../services";
import useSecurityContext from "./useSecurityContext";

export default function useLogin() {
    const { setIsLogin, isLogin } = useSecurityContext();
    const securityService = SecurityService();

    const signIn = async(inputData:any) => {
        let result = await securityService.login(inputData);
        if(result?.accessToken) {
            session.setItem('token', result.accessToken);
            setIsLogin(true);
            return true;
        }
        return false;
    }

    const signOut = async() => {
        let result = await securityService.logout();
        setIsLogin(false);
    }

    return { signIn, signOut, isLogin };
}
