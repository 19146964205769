import React, { useEffect, useState } from 'react';
import './CategoryForm.scss';
import dummyImage from "../../assets/images/dummy.png";
import { Box, Button, Link } from '@mui/material';
import { AlertService, FxButton, FxCheckbox, FxInput, FormValidator } from '@fx/components';
import { CategoryService } from '../../services';

const CategoryForm = (props) => {
  const { closeCategoryForm, id } = props;
  const categoryService = CategoryService();
  let defaultFormModel: any = {
    code: "",
    name: "",
    active: false
  };
  const [formModel, setFormModel] = useState(defaultFormModel);
  const defaultErrors: any = {};
  const [errors, setErrors] = useState(defaultErrors)
  const validationMap: any = {
    'code': ["required"],
    'name': ["required"],
  }

  const handleInputChange = (item: any) => {
    handleFormModelChange({ [item.name]: item.value });
  }

  const handleCheckbox = (event) => {
    handleFormModelChange({ [event.target.name]: event.target.checked });
  };

  const handleFormModelChange = (data: any) => {
    setFormModel((prevState: any) => {
      return { ...prevState, ...data }
    });
  };

  const saveCategory = async () => {
    const validationResult = FormValidator.bulkValidate(validationMap, formModel)
    setErrors(validationResult.errors)
    if (!validationResult.isValid) return
    let response = await categoryService.saveCategory(formModel);
    if (response) {
      AlertService.showSuccessMsg();
    }
  }

  const getCategoryById = async () => {
    let inputData: any = { categoryId : id };
    let response = await categoryService.getCategoryById(inputData);
    setFormModel(response);
  }

  useEffect(() => {
    getCategoryById();
  }, [id]);

  return (
    <Box className='category-form'>
      <Box sx={{ display: "flex", width: { xs: "60%", sm: "38.5%" }, position: "fixed", justifyContent: "space-between", alignItems: "center", px: 2, backgroundColor: "#22AFCE", color: "#ffffff", py: "6px" }}>
        <Box className='font-17'>Category Form</Box>
        <Box><i className="fa-solid fa-circle-xmark font-17 pointer" onClick={closeCategoryForm}></i></Box>
      </Box>
      <Box sx={{ px: 3, pt: 7 }}>
        <Box sx={{ textAlign: "center" }}>
          <Link className="pointer">
            <Box component="img" src={dummyImage} sx={{ height: "350px" }}></Box>
          </Link>
          <Box>
            <i className="fa-solid fa-camera font-20"></i>
          </Box>
        </Box>
        <Box sx={{ pt: 5 }}>
          <FxInput name="code" variant="outlined" label="Category Code" size="small" fullWidth
            value={formModel.code}
            validations={validationMap["code"]}
            validationResult={errors["code"]}
            onValueChange={handleInputChange} disabled={true}/>
        </Box>
        <Box sx={{ pt: 4 }}>
          <FxInput name="name" variant="outlined" label="Category Name" size="small" fullWidth
            value={formModel.name}
            validations={validationMap["name"]}
            validationResult={errors["name"]}
            onValueChange={handleInputChange} />
        </Box>
        <Box sx={{ position: "fixed", bottom: 0, py: 1, backgroundColor: "#fff", width: "37.5%" }}>
          <Button variant="contained" size="medium" fullWidth onClick={saveCategory}>Save</Button>          
        </Box>
      </Box>
    </Box>
  )
};

export default CategoryForm;