import React from 'react';
import { apiClient } from '../context-helpers/ApiClient';

export const CategoryService = () => {

  const getCategories = async (inputData: any) => {
    let apiOptions: any = {
      url: `categories/query`,
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getCategoryById = async (inputData: any) => {
    let apiOptions: any = {
      url: `categories/${inputData.categoryId}`,
      method: 'get'
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const saveCategory = async (inputData: any) => {
    let apiOptions: any = {
      url: `categories`,
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  return { getCategories, getCategoryById,saveCategory };
}