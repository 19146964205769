import { FxButton, FxInput, useNavigation, FxPassword, FormValidator } from "@fx/components";
import { Box, Card, Grid } from "@mui/material";
import React, { useState } from "react";
import "./LandingPage.scss";
import landingImage from "../../assets/images/business.jpg";
import { useDispatch } from 'react-redux';
import useLogin from '../providers/security/useLogin';

const LandingPage = (props: any) => {
  const { navigate } = useNavigation();
  const { signIn, isLogin } = useLogin();

  const defaultErrors: any = {};
  const [errors, setErrors] = useState(defaultErrors)
  const validationMap: any = {
    'userName': ["required"],
    'password': ["required"],
  }

  let defaultFormModel: any = {
    userName: "",
    password: "",
  };
  const [formModel, setFormModel] = useState(defaultFormModel);

  const handleInputChange = (item: any) => {
    handleFormModelChange({ [item.name]: item.value });
  }

  const handleFormModelChange = (data: any) => {
    setFormModel((prevState: any) => {
      return { ...prevState, ...data }
    });
  };

  const login = async () => {
    const validationResult = FormValidator.bulkValidate(validationMap, formModel)
    setErrors(validationResult.errors)
    if (!validationResult.isValid) return

    let result = await signIn(formModel);
    if(result) {
      navigateToHome();
    }
  }

  const navigateToHome = () => {
    let navData = {
      targetRoute: "/orders"
    };
    navigate(navData);
  }

  return (
    <div className="landing-page pt-5">
      <Grid container>
        <Grid item xs={8}>
          <Box component="img" src={landingImage} className='landing-image'></Box>
        </Grid>
        <Grid item xs={4} sx={{ backgroundColor: "#d1f0ff", px: 4 }}>
          <Box sx={{ textAlign: "center", pt: 10, fontSize: "50px", pb: 3, fontWeight: 800 }}>Sign In</Box>
          <Box>
          <FxInput name="userName" variant="outlined" label="Username"
              value={formModel.userName}
              validations={validationMap["userName"]}
              validationResult={errors["userName"]}
              onValueChange={handleInputChange} />
          </Box>
          <Box sx={{ pt: 4, pb: 5 }}>
            <FxPassword name="password" variant="outlined" label="Password"
                value={formModel.password}
                validations={validationMap["password"]}
                validationResult={errors["password"]}
                onValueChange={handleInputChange} />
          </Box>
          <Box><FxButton label="Sign In" variant="contained" fullWidth onClick={login} /></Box>
        </Grid>
      </Grid>
    </div>
  );
}
export default LandingPage;