import { session, AppSettings, AlertService } from '@fx/components';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { ProgressManager } from '../components/progress';

const apiBasePath = AppSettings.APP_API_URL;
//const baseWebURL = AppSettings.APP_BASE_APP_URL + AppSettings.APP_APP_NAME;
//axios.defaults.withCredentials = true;
export const axiosInstance = axios.create({
    baseURL: apiBasePath,
    timeout: 30000,
});

const computeConfig = ({ options, token}:any) => {
  let url = options.url;
  let method = options.method;
  let data = options.data;
  let config: any = {
    baseURL: apiBasePath,
    url,
    method,
    data,
    withCredentials:true
  };
  let tokenHeaders = {};
  let optionHeaders = options.headers || {}; 
  let sessionToken = session.getItem('token'); 
  if (sessionToken) tokenHeaders = {'Authorization': `Bearer ${sessionToken}`}
 
  config.headers = {...tokenHeaders, ...optionHeaders};
  return config;
}

const doAction = async (options: any, token:any="") => {
    const config = computeConfig({ options, token});
    const result = await axiosInstance.request(config);
    return result?.data;
}

const doDownload = (options: any, token: any = "") => {
  let config = computeConfig({ options, token});
  config.responseType = 'blob';
  axiosInstance.request(config)
      .then((res) => {
          let blob = res.data;
          saveAs(blob, options.fileName);
  });
}

//Interceptors starts
axiosInstance.interceptors.request.use((value: any) => {
    ProgressManager.open();
    return value;
}, undefined);

axiosInstance.interceptors.response.use((response) => {
    ProgressManager.close();
    //TODO: handle 401 
    // if (response.data && response.data.message === "Unauth") {
    //     window.location.href = `${baseWebURL}/unauthorize`;
    // }
    return response;
}, (error) => {
    ProgressManager.close();
    if (error.response && error.response.data) {
         if(error.response.data.message)  {
           AlertService.showErrorMsg(error.response.data.message);
         }
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
});
//Interceptors ends

export const apiClient = {
  doAction: doAction,
  doDownload: doDownload
}

