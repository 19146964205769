import { AppBar, Box, Drawer, Toolbar } from "@mui/material";
import React, { useState } from "react";
import LeftMenu from "../left-menu/LeftMenu";
import useLogin from "../providers/security/useLogin";
import "./Header.scss";

export const Header = (props: any) => {

  const { isLogin } = useLogin();
  const [leftMenuOpen, setLeftMenuOpen] = useState(false); 
  const openLeftMenu = (item: any) => {    
    setLeftMenuOpen(true);
  }

  const closeLeftMenu = () => {
    setLeftMenuOpen(false);
  }

  return (
    <div className="header">
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, scrollBehavior: "smooth" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>   
        <Box sx={{fontSize: "20px"}}><i className="fa-solid fa-bars font-20 pointer" onClick={openLeftMenu}></i></Box>
          {/* {isLogin && <Box sx={{fontSize: "20px"}}><i className="fa-solid fa-bars font-20 pointer" onClick={openLeftMenu}></i></Box>} */}
          <Box className="font-24">Chennai Jewels Admin</Box>    
          <Box></Box>     
        </Toolbar>
      </AppBar> 
      <Drawer
        anchor="left"
        open={leftMenuOpen}
        onClose={closeLeftMenu}
        PaperProps={{
          sx: { width: { xs: "60%", sm: "40%", md: "20%" } },
        }}>
        <LeftMenu closeAction={closeLeftMenu} />
      </Drawer>     
    </div>
  );
}
