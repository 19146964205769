import React, { useState, useEffect, Suspense } from "react";
import './App.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Header } from "./components/header/Header";
import { ToastContainer } from 'react-toastify';
import LandingPage from "./components/landingPage/LandingPage";
import { createTheme, ThemeProvider } from "@mui/material";
import { useSelector } from 'react-redux';
import { AllRoutes } from "./AllRoutes";
import Orders from "./components/orders/Orders";
import MenuList from "./components/menuList/MenuList";
import TileList from "./components/tileList/TileList";
import SuperAdmin from "./components/superAdmin/SuperAdmin";
import AccountUser from "./components/accountUser/AccountUser";
import { SecurityProvider } from "./components/providers/security/SecurityProvider";

const PageLoader: React.FC = () => <div />

function App() {
  const businessCategory = useSelector<any, any>(s => s.businessCategory);
  const themeData: any = {
    palette: {
      primary: {
        main: "#22AFCE",
        contrastText: "#FFFFFF"
      },
      secondary: {
        main: "#FFFFFF"
      },
    }
  }

  const businessCategoryChanged = () => {
  }

  useEffect(() => {
    businessCategoryChanged();
  }, [businessCategory]);

  const theme = createTheme(themeData);

  const renderRoute = (route: any) => {
    const Component = route.component
    return <Route path={route.path}
      component={Component} />
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <SecurityProvider>
          <Router>
            <Header />
            <Suspense fallback={<PageLoader />}>
              {AllRoutes.map((route: any) => renderRoute(route))}
              <Route exact={true} path="/" component={LandingPage} />
               <Route exact={true} path="/menulist" component={MenuList} />
              {/*<Route exact={true} path="/tilelist" component={TileList} />
              <Route exact={true} path="/orders" component={Orders} />
              <Route exact={true} path="/superadmin" component={SuperAdmin} />
              <Route exact={true} path="/accountuser" component={AccountUser} /> */}
            </Suspense>
            <ToastContainer />
          </Router>
        </SecurityProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
