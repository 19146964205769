import React from 'react';
import './LeftMenu.scss';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigation } from '@fx/components';
import useLogin from '../providers/security/useLogin';
import { Box } from '@mui/material';

const LeftMenu = (props: any) => {
  const { navigate } = useNavigation();
  const { signOut } = useLogin();

  const menus = [
    { title: 'Orders', route: '/orders', icon: <i className="fa-solid fa-list-ol"></i> },
    // { title: 'Profile', route: '/storeprofile', icon: <i className="fa-solid fa-user"></i> },
     { title: 'Menu List', route: '/menulist', icon: <i className="fa-solid fa-list-ul"></i> },
    // { title: 'Tiles', route: '/tilelist', icon: <i className="fa-solid fa-list"></i> },
    // { title: 'Tables', route: '/tablelist', icon: <i className="fa-solid fa-table-list"></i> },
    // { title: 'Super Admin', route: '/superadmin', icon: <i className="fa-solid fa-user"></i> },
    // { title: 'Account User', route: '/accountuser', icon: <i className="fa-solid fa-user"></i> },
    { title: 'Logout', route: '/', icon: <i className="fa-solid fa-right-from-bracket"></i> }
  ];

  const openMenu = (menu) => {
    if (menu.title == 'Logout') {
      signOut();
      props.closeAction();
    } 
    let navData = {
      targetRoute: menu.route,
      targetTitle: menu.title,
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
    props.closeAction();
  }

  return (
    <Box sx={{pt: 8, pl: 1}}>
    <List>
      {menus.map((menu: any) => (
        <ListItem disablePadding>
          <ListItemButton onClick={() => openMenu(menu)}>
            <ListItemIcon>
              {menu.icon}
            </ListItemIcon>
            <ListItemText primary={menu.title} />
          </ListItemButton>
        </ListItem>
      ))
      }
    </List>
    </Box>
  );
}


export default LeftMenu;