import { FxButton, FxInput } from '@fx/components';
import { Box, Card, Drawer, Grid, Switch, Paper, Fade, Popper, Button } from '@mui/material';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import React, { useState, useEffect } from 'react';
import CategoryForm from '../categoryForm/CategoryForm';
import ItemForm from '../itemForm/ItemForm';
import { CategoryService, ProductService } from '../../services';
import pendant from "../../assets/images/category1.png";
import necklace from "../../assets/images/necklace1.png";
import choker from "../../assets/images/necklace4.png";
import mala from "../../assets/images/mala.jpg";
import haram from "../../assets/images/haram.jpg";
import pendant1 from "../../assets/images/pendant1.jpg";
import pendant2 from "../../assets/images/pendant2.jpg";
import pendant3 from "../../assets/images/pendant3.jpg";


const MenuList = (props: any) => {
  const categoryService = CategoryService();
  const productService = ProductService();
  const categoriesList = [
    {
      categoryName: "Pendant Sets", categoryImage: pendant,
      itemList: [{ itemName: "Pendant set", itemImage: pendant1 }, { itemName: "Pendant set", itemImage: pendant2 }, { itemName: "Pendant set", itemImage: pendant3 }]
    },
    {
      categoryName: "Necklaces", categoryImage: necklace,
      subCategoryList: [
        { subCategoryName: "Choker", subCategoryImage: choker, itemList: [{ itemName: "White with Maroon stone Necklace set", itemImage: choker }] },
        { subCategoryName: "Mala", subCategoryImage: mala, itemList: [{ itemName: "Orange kemp with AD Stones necklace set", itemImage: mala }] },
        { subCategoryName: "Haram", subCategoryImage: haram, itemList: [{ itemName: "Gold Antique Gold beaded necklace set", itemImage: haram }] },
      ]
    }
  ];

  const productList = [
    { productImage: pendant, productName: "Pendant Set" }, { productImage: necklace, productName: "Necklaces" }, { productImage: choker, productName: "Choker" },
  ]

  let defaultItem: any = {
    searchCategory: '',
    searchProduct: ''
  };
  const [item, setItem] = useState(defaultItem);

  const defaultCurrentCategoryId = "";
  const [currentCategoryId, setCurentCategoryId] = useState(defaultCurrentCategoryId);

  const defaultCurrentItemId = "";
  const [currentItemId, setCurentItemId] = useState(defaultCurrentItemId);

  const defaultItems = [];
  const [items, setItems] = useState(defaultItems);
  const [categoryList, setCategoryList] = useState([]);

  const handleCategoryChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  };

  const handleProductChange = (item) => {
    handleItemChange({ [item.name]: item.value });

  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getItems = async (cat) => {
    let inputData: any = {
      data: {
        filters: {
          categoryId: cat._id
        }
      }
    };

    let response = await productService.getProducts(inputData);
    let items = response.items;
    for (let item of response.items) {
      item.productImage = item.defaultImageUrl ? `${process.env.REACT_APP_IMAGE_BASE_URL}/${item.defaultImageUrl}` : item.defaultImageUrl;
    }
    setItems(items);
  }

  const categoryClick = (cat: any) => {
    getItems(cat);
  }

  //const [subCategoryitems, setsubCategoryitems] = useState(0); 
  const subCategoryClick = (subCat: any) => {
    getItems(subCat);
  }

  const [categoryFormOpen, setCategoryFormOpen] = useState(false);
  const openCategoryForm = () => {
    setCategoryFormOpen(true);
  }
  const closeCategoryForm = () => {
    setCategoryFormOpen(false);
  }

  const [itemFormOpen, setItemFormOpen] = useState(false);
  const openItemForm = () => {
    setItemFormOpen(true);
  }
  const closeItemForm = () => {
    setItemFormOpen(false);
  }

  const getCategoryList = async () => {
    let inputData: any = {
      data: {
        filters: {
          name: ""
        }
      }
    };

    let response = await categoryService.getCategories(inputData);
    let items = response.items;
    for (let item of response.items) {
      item.categoryImage = item.defaultImageUrl ? `${process.env.REACT_APP_IMAGE_BASE_URL}/${item.defaultImageUrl}` : item.defaultImageUrl;
    }
    setCategoryList(items);
  }

  const editCategory = (item:any) => {
    setCurentCategoryId(item._id);
    openCategoryForm();
  }

  const deleteCategory = (item:any) => {
    
  }

  const editSubCategory = (item:any) => {
    setCurentCategoryId(item._id);
    openCategoryForm();
  }

  const deleteSubCategory = (item:any) => {
    
  }

  useEffect(() => {
    getCategoryList();
  }, []);


  return (
    <div>
      <Box sx={{ backgroundColor: "#e0f9ff", mt: 8, py: 1, color: "#22AFCE", fontSize: "16px", pl: 3, fontWeight: 600, border: "1px solid #22AFCE" }}>
        Menu List
      </Box>
      {/* <Grid container sx={{ pt: 5, px: 3 }} spacing={4}>
        <Grid item xs={4}>
          <FxInput name="searchText" placeholder="Search Category" variant="outlined" value={item.searchCategory}
            onValueChange={handleCategoryChange} />
        </Grid>
        <Grid item xs={4}>
          <FxInput name="search" placeholder="Search Item" variant="outlined" value={item.searchItem}
            onValueChange={handleProductChange} />
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" size="small">Upload Items</Button>          
        </Grid>
      </Grid> */}

      <Grid container sx={{ pt: 5, px: 3 }} spacing={4}>
        <Grid item xs={4}>
          <Card sx={{
            pl: 3, pb: 2, height: "500px"
          }}>
            <Box sx={{ fontWeight: 600, fontSize: 19, position: 'fixed', backgroundColor: "#fff", py: 2, pr: 3, width: "30.6%", display: "flex", justifyContent: "space-between" }}>
              <Box>Category | {categoryList.length}</Box>
              <Box sx={{ pr: 2, color: "#22AFCE", cursor: "pointer" }}><i className="fa-solid fa-circle-plus font-22" onClick={openCategoryForm}></i></Box>
            </Box>
            <Box sx={{
              height: "450px", overflowY: "auto", pt: 5,
              "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#F5F5F5"
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
                backgroundColor: "#F5F5F5"
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                backgroundColor: "#808080"
              }
            }}>
              {categoryList.length > 0 && categoryList.map((item) => (
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ fontSize: 17, pt: 2, cursor: "pointer", display: "flex", alignItems: "center" }} onClick={() => categoryClick(item)}>
                      {/* <Box component="img" src={item.categoryImage} sx={{ height: "60px", width: "60px" }} /> */}
                      <Box sx={{ pl: 3 }}>{item.name}</Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <Box><Switch defaultChecked size="small" /></Box> */}
                      <PopupState variant="popper" popupId="demo-popup-popper">
                        {(popupState: any) => (
                          <div>
                            <Box sx={{ pl: 3, pr:2, cursor: "pointer", fontSize: "19px" }} {...bindToggle(popupState)}>
                              <i className="fas fa-ellipsis-v"></i>
                            </Box>
                            <Popper {...bindPopper(popupState)} transition>
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper>
                                    <Box sx={{ px: "7px", py: "5px", cursor: "pointer", fontSize: "14px" }} onClick={() => editCategory(item)}>Edit</Box>
                                    <Box sx={{ px: "7px", py: "5px", cursor: "pointer", fontSize: "14px" }} onClick={() => deleteCategory(item)}>Delete</Box>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        )}
                      </PopupState>         
                    </Box>
                  </Box>
                  <Box>
                    {item.subItems && item.subItems.map((subItem) => (
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ fontSize: 17, pt: 2, cursor: "pointer", pl: 3, display: "flex", alignItems: "center" }} onClick={() => subCategoryClick(subItem)}>
                          {/* <Box component="img" src={subItem.categoryImage} sx={{ height: "60px", width: "60px" }} /> */}
                          <Box sx={{ pl: 3 }}>{subItem.name}</Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {/* <Box><Switch defaultChecked size="small" /></Box> */}
                          <PopupState variant="popper" popupId="demo-popup-popper">
                        {(popupState: any) => (
                          <div>
                            <Box sx={{ pl: 3, pr:2, cursor: "pointer", fontSize: "19px" }} {...bindToggle(popupState)}>
                              <i className="fas fa-ellipsis-v"></i>
                            </Box>
                            <Popper {...bindPopper(popupState)} transition>
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper>
                                    <Box sx={{ px: "7px", py: "5px", cursor: "pointer", fontSize: "14px" }} onClick={() => editSubCategory(item)}>Edit</Box>
                                    <Box sx={{ px: "7px", py: "5px", cursor: "pointer", fontSize: "14px" }} onClick={() => deleteSubCategory(item)}>Delete</Box>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        )}
                      </PopupState>                         
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{
            pl: 3, pb: 2, height: "500px"
          }}>
            <Box sx={{ fontWeight: 600, fontSize: 19, position: 'fixed', backgroundColor: "#fff", py: 2, pr: 3, width: "30%", display: "flex", justifyContent: "space-between" }}>
              <Box>Items | {items.length}</Box>
              <Box sx={{ pr: 2, color: "#22AFCE", cursor: "pointer" }}><i className="fa-solid fa-circle-plus font-22" onClick={openItemForm}></i></Box>
            </Box>
            <Box sx={{
              height: "450px", overflowY: "auto", pt: 5,
              "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#F5F5F5"
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
                backgroundColor: "#F5F5F5"
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                backgroundColor: "#808080"
              }
            }}>
              {items.length > 0 && items.map((item) => (
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ fontSize: 17, pt: 2, cursor: "pointer", display: "flex", alignItems: "center" }}>
                      <Box component="img" src={item.productImage} sx={{ height: "60px", width: "60px" }} />
                      <Box sx={{ pl: 3 }}>{item.name}</Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <Box><Switch defaultChecked size="small" /></Box> */}
                      <PopupState variant="popper" popupId="demo-popup-popper">
                        {(popupState: any) => (
                          <div>
                            <Box sx={{ pl: 3, pr:3, cursor: "pointer", fontSize: "19px" }} {...bindToggle(popupState)}>
                              <i className="fas fa-ellipsis-v"></i>
                            </Box>
                            <Popper {...bindPopper(popupState)} transition>
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper>
                                    <Box sx={{ px: "7px", py: "5px", cursor: "pointer", fontSize: "14px" }}>Edit</Box>
                                    <Box sx={{ px: "7px", py: "5px", cursor: "pointer", fontSize: "14px" }}>Delete</Box>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        )}
                      </PopupState>         
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={categoryFormOpen}
        onClose={closeCategoryForm}
        PaperProps={{
          sx: { width: { xs: "60%", sm: "40%" } },
        }}>
        <CategoryForm closeCategoryForm={closeCategoryForm} id={currentCategoryId}/>
      </Drawer>
      <Drawer
        anchor="right"
        open={itemFormOpen}
        onClose={closeItemForm}
        PaperProps={{
          sx: { width: { xs: "60%", sm: "40%" } },
        }}>
        <ItemForm closeItemForm={closeItemForm} />
      </Drawer>
    </div>
  );
}
export default MenuList;