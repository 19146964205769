import React from 'react';
import './ItemForm.scss';
import dummyImage from "../../assets/images/dummy.png";
import { Box, Button, Link } from '@mui/material';
import { FxButton, FxCheckbox, FxInput, FxRadio, FxSelect, FxTextArea } from '@fx/components';


const ItemForm = (props) => {
const closeItemForm=props.closeItemForm;
  return (
    <Box className='item-form'>
      <Box sx={{ display: "flex",  width: { xs: "60%", sm: "38.5%"}, position: "fixed", justifyContent: "space-between", alignItems: "center", px: 2, backgroundColor: "#22AFCE", color: "#ffffff", py: "6px" }}>
        <Box className='font-17'>Item Form</Box>
        <Box><i className="fa-solid fa-circle-xmark font-17 pointer" onClick={closeItemForm}></i></Box>
      </Box>
      <Box sx={{ px: 3, pt: 7 }}>
        <Box sx={{ textAlign: "center" }}>
          <Link className="pointer">
            <Box component="img" src={dummyImage} sx={{ height: "350px" }}></Box>
          </Link>
          <Box>
            <i className="fa-solid fa-camera font-20"></i>
          </Box>
        </Box>
        <Box sx={{ pt: 5 }}>
          <FxInput name="itemCode" variant="outlined" label="Item Code" size="small" fullWidth
            value="" />
        </Box>
        <Box sx={{ pt: 4 }}>
          <FxInput name="itemName" variant="outlined" label="Item Name" size="small" fullWidth
            value="" />
        </Box>
        <Box sx={{ pt: 4 }}>
          <FxSelect name='itemCategory' variant='outlined' label='Categories' size='small' valueField='' selectedValue="" displayField="text" options={["Wine", "Spirits", "Gifts"]} onValueChange={() => {}}  />
        </Box>       
        <Box sx={{ pt: 4, pr: 2 }}>
          <FxTextArea name="itemDescription" label="Description" onValueChange={() => {}} maxRows={5} minRows={4} value="" />      
        </Box>
        <Box sx={{ pt: 4 }}>
          <Box>Tax Type</Box>
          <Box sx={{pt: 1, pl: 1}}>
          <FxRadio name="taxInclusive" label='Inclusive' labelPlacement='end' onValueChange={() => {}} />     
          <FxRadio name="taxExclusive" label='Exclusive' labelPlacement='end' onValueChange={() => {}} />  
          </Box>             
        </Box>
        <Box sx={{ pt: 3, pb: 7 }}>
          <FxCheckbox name="active" label="Active" labelPlacement="end" onValueChange={() => { }} />          
        </Box>
        <Box sx={{position: "fixed", bottom: 0, py: 1, backgroundColor: "#fff", width: "37.5%"}}>
          <Button variant="contained" size="medium" fullWidth>Save</Button>       
        </Box>
      </Box>
    </Box>
  )
};

export default ItemForm;