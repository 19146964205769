import React from 'react';
import { apiClient } from '../context-helpers/ApiClient';

export const ProductService = () => {

  const getProducts = async (inputData: any) => {
    let apiOptions: any = {
      url: 'products/query',
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  return { getProducts};
}